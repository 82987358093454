import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  ElementType,
  Fragment,
  ReactNode,
  useState,
} from 'react'

import { useElementHeight } from '@/hooks/useElementRect'
import { bezier } from '@/theme/mixins'

export interface AccordionItemData {
  __typename?: undefined
  heading: string | ReactNode
  subheading?: string | ReactNode
  content: ReactNode
}

interface Props extends ComponentPropsWithoutRef<'li'> {
  data: AccordionItemData | null
}

const AccordionItem = ({ data, ...props }: Props): JSX.Element => {
  const HeadingTag = (data?.__typename ? 'h3' : Fragment) as ElementType
  const SubheadingTag = (
    data?.__typename ? 'h4' : Fragment
  ) as ElementType
  const [open, setOpen] = useState(false)
  const [heightRef, setHeightRef] = useState<HTMLDivElement | null>(
    null
  )
  const contentHeight = useElementHeight(heightRef)
  const styles = {
    listItem: css`
      color: var(--text-color);
      &:not(:last-of-type) {
        margin-bottom: 2em;
      }
    `,
    button: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid var(--border-color);
      @media (hover: hover) {
        &:hover {
          color: var(--hover-color);
        }
      }
    `,
    buttonText: css`
      font-size: var(--fs-30);
      font-family: var(--ff-display);
      padding: 0.5em 0;
      ${data?.__typename &&
      css`
        padding: 0.75em 0;
      `}
    `,
    heading: css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: inherit;
        padding: 0;
        margin: 0;
      }
    `,
    subheading: css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: var(--fs-16);
        font-weight: 400;
        font-style: italic;
        opacity: 0.75;
        padding: 0;
        margin: 0.333em 0 0.25em;
      }
    `,
    buttonIcon: css`
      flex: none;
      font-size: var(--fs-24);
      width: 1em;
      height: 1em;
      position: relative;
      color: var(--button-color);
      transform: scale3d(0.999, 0.999, 1);
      transition: all 200ms ease;
      margin-right: var(--gtr-s);
      /* margin-top: calc(0.5 * var(--fs-36)); */
      &::before,
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        position: absolute;
        top: calc(50% - 1px);
        left: 0%;
        transition: transform 400ms ease;
      }
      &::before {
        transform: ${open
          ? `rotate3d(0, 0, 1, 135deg)`
          : `rotate3d(0, 0, 0, 90deg)`};
      }
      &::after {
        transform: ${open
          ? `rotate3d(0, 0, 1, -135deg)`
          : `rotate3d(0, 0, 1, 90deg)`};
      }
      @media (hover: hover) {
        button:hover > & {
          color: var(--hover-color);
          transform: scale3d(1.25, 1.25, 1);
        }
      }
    `,
    content: css`
      position: relative;
      overflow: hidden;
      height: ${open ? contentHeight : 0}px;
      transition: height 500ms ${bezier.easeOut};
      > div {
        border-top: 1px solid var(--content-border-color);
      }
      ${data?.__typename &&
      css`
        > div {
          padding: 0 0 3em;
          > div > p:first-of-type {
            margin-top: 0;
          }
        }
      `}
    `,
  }
  return (
    <li
      css={styles.listItem}
      {...props}
    >
      <button
        css={styles.button}
        onClick={() => setOpen(prev => !prev)}
        aria-label={`Toggle accordion`}
      >
        <div css={styles.buttonText}>
          <div css={styles.heading}>
            <HeadingTag>{data?.heading}</HeadingTag>
          </div>
          {data?.subheading && (
            <div css={styles.subheading}>
              <SubheadingTag>{data?.subheading}</SubheadingTag>
            </div>
          )}
        </div>
        <div css={styles.buttonIcon} />
      </button>
      <div css={styles.content}>
        <div ref={node => setHeightRef(node)}>{data?.content}</div>
      </div>
    </li>
  )
}

export default AccordionItem
